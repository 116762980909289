import axios from "axios";

async function getCustomer(customerPin) {
  try {
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/customers/get-by-pin/${customerPin}`
    );
  } catch (err) {
    throw err;
  }
}

export default getCustomer;
