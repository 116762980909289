import React, { useEffect, useState } from "react";
import getReports from "../api/reports";
import { Card } from "react-bootstrap";
import getCustomer from "../api/customers";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import spinner from "../images/spinner.gif";
import sendEmails from "../api/emails";

function EmailSender() {
  const [pin, setPin] = useState("");
  const [period, setPeriod] = useState("");
  const [error, setError] = useState("");
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState(0);

  function donwloadFile() {
    var bytes = new Uint8Array(records[0].FILECONTENT.data);

    var blob = new Blob([bytes], { type: "application/pdf" });

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${pin}-${period}.pdf`;
    link.click();
  }

  function logOut() {
    Cookies.remove("jwt");
    window.location.replace("/login");
  }

  function onPeriodChange(e) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      setPeriod(e.target.value);
    }
  }

  async function sendEmail() {
    try {
      setIsLoading(true);
      const result = await sendEmails(pin, period);
      console.log(result.status);
      console.log(result.status === 200);
      console.log(result.status == 200);
      if (result.status === 200) toast.success("Emails sent");
      else toast.warning("Emails not sent");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  async function searchRecrods() {
    try {
      if (validate()) {
        setIsLoading(true);
        if (pin) {
          var customer = await getCustomer(pin);

          if (customer.data.length === 0) {
            setError("Customer with this PIN doesn't exists");
            return false;
          }
        }
        var result = await getReports(pin, period);
        setRecords(result.data.records);
        setRecordsCount(result.data.records.length);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  function validate() {
    if (!period) {
      setError("Period is required");
      return false;
    }
    setError("");
    return true;
  }

  function resetForm() {
    setPin("");
    setPeriod("");
    setError("");
    setRecordsCount(0);
    setRecords([]);
    setError("");
  }
  return (
    <div className="container">
      <button className="btn log-out" onClick={logOut}>
        <i className="fa fa-sign-out"></i> Logout
      </button>
      <div className="centered">
        <Card>
          <div className="email-client-input">
            <label>PIN</label>
            <input
              type={"text"}
              className="form-control"
              onChange={(e) => setPin(e.target.value)}
              value={pin}
            ></input>
          </div>
          <div className="email-client-input">
            <label>Period</label>
            <input
              type="text"
              className="form-control"
              onChange={onPeriodChange}
              name={period}
              value={period}
              required
            ></input>
          </div>
          <div className="email-client-input">
            <p>Records : {recordsCount}</p>
          </div>
          {isLoading ? (
            <div>
              <img src={spinner} alt="spinner"></img>
            </div>
          ) : recordsCount > 0 ? (
            <div className="email-client-input">
              {pin && recordsCount === 1 ? (
                <button className="btn" onClick={donwloadFile}>
                  <i className="fa fa-download"></i> Download
                </button>
              ) : null}
              <button className="btn" onClick={sendEmail}>
                <i class="fa fa-paper-plane" aria-hidden="true"></i> Send email
              </button>
              <button className="btn" onClick={resetForm}>
                {" "}
                <i className="fa fa-refresh"></i> Reset Form
              </button>
            </div>
          ) : (
            <div className="email-client-input">
              <button
                className="form-control btn send-btn"
                onClick={searchRecrods}
              >
                <i className="fa fa-search"> </i>
                Search
              </button>
            </div>
          )}
          <p className="error">{error}</p>
        </Card>
      </div>
    </div>
  );
}

export default EmailSender;
