import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import login from "../api/auth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function Login({ authenticate }) {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      const result = await login(user, password);
      if (result.status === 200) {
        authenticate();
        Cookies.set("jwt", result.data.token);
        navigate("email-client");
      }
    } catch (error) {
      if (error.response && error.response.status === 401)
        setError("Wrong username or password");
    }
  }

  return (
    <div className="Login centered">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="user">
          <Form.Label className="label">User</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password" className="top-10px">
          <Form.Label className="label">Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button size="lg" type="submit" className="btn form-control login-btn">
          Login
        </Button>
        <p className="error">{error}</p>
      </Form>
    </div>
  );
}
