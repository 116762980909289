import axios from "axios";

async function sendEmails(pin, period) {
  try {
    var result = await axios.post(
      `${process.env.REACT_APP_API_URL}/emails/send-mastercard-reports`,
      {
        pin,
        period,
      }
    );
    return result;
  } catch (err) {
    console.log("err ", err);
    throw err;
  }
}

export default sendEmails;
