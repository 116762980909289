import axios from "axios";

async function getReports(v_pin, period) {
  try {
    var result = await axios.post(
      `${process.env.REACT_APP_API_URL}/reports/customer-mastercard-reports`,
      {
        v_pin,
        period,
      }
    );
    console.log(result);
    return result;
  } catch (err) {
    console.log("err ", err);
    throw err;
  }
}

export default getReports;
