import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Logo from "./images/iutelogos/100 x 100.png";
import EmailSender from "./Components/EmailSender";
import { useEffect, useState } from "react";
import LoginPage from "./Components/Login";
import Cookies from "js-cookie";
import ProtectedRoute from "./Components/ProtectedRoute";
import CustomWrapper from "./Components/ProtectedRoute";
import Auth from "./Components/Auth";
import { getUserFromJWT } from "./utils/jwt.decode";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    let user = getUserFromJWT();
    user ? setAuth(true) : setAuth(false);
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        {!auth && (
          <Route
            path="/login"
            element={<LoginPage authenticate={() => setAuth(true)} />}
          />
        )}

        {auth && (
          <>
            <Route
              path="/email-client"
              element={<EmailSender logout={() => setAuth(false)} />}
            />
          </>
        )}
        <Route
          path="*"
          element={<Navigate to={auth ? "/email-client" : "/login"} />}
        />
      </Routes>
    </div>
  );
};

export default App;
